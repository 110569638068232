import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import InputPhone from '../../components/Form/input/InputPhone'
import { NATIONALITIES } from '../../constants/url'
import { GetPlatform } from '../../containers/RegisterContainer'
import { useSession } from '../../containers/SessionContainer'
import { composeValidators } from '../../utils/Form'
import api from '../../utils/Requester'
import {
	maxLong,
	required,
	siretForeignerSize,
	siretSize,
	siretFormat,
} from '../../utils/VerifyFields'
import Form from '../Form/Form'
import FormButton from '../Form/FormButton'
import FormIcon from '../Form/FormIcon'
import InputMultipleSelect from '../Form/input/InputMultipleSelect'
import InputNumber from '../Form/input/InputNumber'
import InputSelect from '../Form/input/InputSelect'
import InputText from '../Form/input/InputText'
import InputTextArea from '../Form/input/InputTextArea'
import SelectMenuItem from '../Form/input/SelectMenuItem'
import SlidingForm from '../Form/SlidingForm'

const useStyles = makeStyles((theme) => ({
	size1: {
		width: '45%',
		marginRight: '10%',
		marginTop: '15px',
		marginBottom: '15px',
		display: 'inline-block',
	},
	size2: {
		width: '45%',
		marginTop: '15px',
		marginBottom: '15px',
		display: 'inline-block',
	},
	size3: {
		width: '100%',
		marginTop: '15px',
		marginBottom: '15px',
		display: 'inline-block',
	},
	validateRoot: {
		display: 'block',
		margin: '0 auto',
	},
	justify: {
		marginTop: '15px',
		marginBottom: '15px',
	},
}))

function EEInfoForm({ open, close, infoData }) {
	const classes = useStyles()
	const { t } = useTranslation(['user', 'validate'])
	const [cookies] = useCookies()
	const [sites, setSites] = useState([{}])
	const [nationalities, setNationalities] = useState([{}])
	const [nationality, setNationality] = useState(1)
	const [platforms, setPlatforms] = useState([])
	const { session, setSession } = useSession('signup')
	const [initalValues, setInitialValues] = useState({})
	const { enqueueSnackbar } = useSnackbar()

	const onSubmit = (values) => {
		let initialVal = {}

		Object.assign(values, formatSelectedPlatforms(values))

		for (const key of Object.keys(values)) {
			if (key.includes('justify_')) delete values[key]
			if (key.includes('do_')) delete values[key]
		}

		if (!session) Object.assign(initialVal, { eeInfo: values })

		let tmpSession = JSON.parse(session)

		if (tmpSession) Object.assign(initialVal, { ...tmpSession, eeInfo: values })
		setSession(JSON.stringify(initialVal))
		close()
	}

	const formatSelectedPlatforms = (dt) => {
		const platforms = []
		const keys = Object.keys(dt).filter((plt) => plt.includes('justify_') || plt.includes('do_'))

		keys.forEach((x) => {
			const find = platforms.findIndex((y) => y.platformId === x.split('_')[1])
			if (find !== -1)
				platforms[find] = {
					...platforms[find],
					justification: x.includes('justify_') ? dt[x] : platforms[find].justification,
					do: x.includes('do_') ? dt[x] : platforms[find].do,
				}
			else
				platforms.push({
					platformId: x.split('_')[1],
					justification: x.includes('justify_') ? dt[x] : '',
					do: x.includes('do_') ? dt[x] : '',
				})
		})
		return {
			platforms,
		}
	}

	useEffect(() => {
		if (!session) return

		let tmpSession = JSON.parse(session)

		if (tmpSession && tmpSession.eeInfo && tmpSession.eeInfo.platforms) {
			let eeInfo = tmpSession.eeInfo
			let plts = eeInfo.platforms
			let reformatedPlts = plts.map((plt) => ({
				['justify_' + plt.platformId]: plt.justification,
				['do_' + plt.platformId]: plt.do,
			}))
			let selectedPlts = plts.map((plt) => plt.platformId)

			setPlatforms(selectedPlts)
			setNationality(eeInfo.companyNationality)

			Object.assign(eeInfo, ...reformatedPlts)

			delete eeInfo.platforms

			setInitialValues(tmpSession.eeInfo)
		}
	}, [open])

	useEffect(() => {
		GetNationalities()
		GetPlatform(cookies, function (res, error) {
			if (error) {
				return
			}
			if (res) {
				setSites(res.data)
			}
		})
	}, [])

	const recoveryNationalities = (res) => {
		const { data, problem, status } = res
		if (problem)
			enqueueSnackbar(`${t('error')} ${status} : ${t('view_render_failed')}`, {
				variant: 'error',
			})
		else setNationalities(data)
	}

	const GetNationalities = () => {
		api.get(NATIONALITIES).then(recoveryNationalities)
	}

	const SiretPerNation = {
		fr: 285,
	}

	return (
		<SlidingForm
			direction="fullscreen"
			open={open}
			title={t('InformationAboutExternCompany')}
			iconAttachement={
				<>
					<FormIcon icon="clear" onClick={close} />
				</>
			}
			onClose={close}
		>
			<Form onSubmit={onSubmit} initialValues={initalValues}>
				<InputMultipleSelect
					onChange={(e) => {
						setPlatforms(e.target.value)
					}}
					name="platform"
					label={t('platform')}
					classes={{
						root: classes.size1,
					}}
				>
					{sites.map((site, i) => {
						return (
							site.platformId && (
								<SelectMenuItem key={site.platformId} value={site.platformId}>
									{site.name}
								</SelectMenuItem>
							)
						)
					})}
				</InputMultipleSelect>
				{platforms.map((platform) =>
					sites.map((site, i) => {
						if (site.platformId === parseInt(platform)) {
							return (
								<>
									<InputTextArea
										classes={{ root: classes.justify }}
										name={`justify_${site.platformId}`}
										key={`justify_${i}`}
										label={t('justification') + ` - ${site.name}`}
										validate={required}
									/>
									<InputTextArea
										name={`do_${site.platformId}`}
										key={`do_${i}`}
										label={t('do') + ` - ${site.name}`}
										validate={required}
									/>
								</>
							)
						}
						return ''
					}),
				)}
				<InputText
					classes={{
						root: classes.size1,
					}}
					name="companyName"
					label={t('companyName')}
					validate={composeValidators(required, maxLong)}
				/>
				<InputText
					classes={{
						root: classes.size2,
					}}
					name="socialReason"
					label={t('socialReason')}
					validate={composeValidators(required, maxLong)}
				/>
				<InputText
					classes={{
						root: classes.size1,
					}}
					name="agencyName"
					label={t('agencyName')}
					validate={composeValidators(required, maxLong)}
				/>
				<InputPhone
					classes={{ root: classes.size2 }}
					name="agencyPhone"
					label={t('agencyPhone')}
					validate={composeValidators(required)}
				/>
				<InputSelect
					name="companyNationality"
					label={t('companyNationality')}
					classes={{
						root: classes.size1,
					}}
					validate={composeValidators(required)}
					onChange={(e) => {
						setNationality(e.target.value)
					}}
				>
					{nationalities.map((nationality, i) => {
						return (
							<SelectMenuItem key={i} value={nationality.id}>
								{nationality.name}
							</SelectMenuItem>
						)
					})}
				</InputSelect>
				{nationality && nationality === SiretPerNation.fr ? (
					<InputNumber
						classes={{
							root: classes.size2,
						}}
						name="siretNumber"
						label={t('siretNumber')}
						validate={composeValidators(required, siretSize, siretFormat)}
					/>
				) : (
					<InputText
						classes={{
							root: classes.size2,
						}}
						name="siretNumber"
						label={t('siretNumber')}
						validate={composeValidators(required, siretForeignerSize, siretFormat)}
					/>
				)}
				<FormButton classes={{ root: classes.validateRoot }} label={t('validate')} type="submit" />
			</Form>
		</SlidingForm>
	)
}
export default EEInfoForm
